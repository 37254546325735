import React from 'react';
import { API } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import logo from './logo.svg';
import './App.css';

const ADMIN_GROUP_NAME = "admin"

const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
      return onAuthUIStateChange(async (nextAuthState, authData) => {
          setAuthState(nextAuthState);
          setUser(authData)
          if (authData) {
            const userGroups = authData.signInUserSession.accessToken.payload["cognito:groups"] || []
            const userIsAdmin = userGroups.includes(ADMIN_GROUP_NAME)
            console.log("user is an admin?", userIsAdmin)
          }
      });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <App />
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignUp
        slot="sign-up"
        formFields={[
          { type: "username" },
          { type: "password" },
          { type: "email" }
        ]}
      />
    </AmplifyAuthenticator>
  );
}

function App() {
  const apiName = 'backend';
  const path = '/unprotected'; 

  React.useEffect(() => {
    async function fetch() {
      // https://docs.amplify.aws/lib/restapi/getting-started/q/platform/js#automated-setup-create-new-rest-api
      const resp = await API.get(apiName, path)
      console.log("resp from api", resp)
    }
    fetch()
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <AmplifySignOut />
      </header>
    </div>
  );
}


export default AuthStateApp