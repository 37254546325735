import { Auth } from 'aws-amplify';

function getAwsConfig() {
  const endpoint = new URL(window.env.public_url)
  return {
    Auth: {
      region: "us-east-1",
      userPoolId: window.env.cognito.poolId,
      userPoolWebClientId: window.env.cognito.clientId,
      mandatorySignIn: true,
      cookieStorage: {
        domain: endpoint.hostname,
        path: "/",
        expires: 365,
        secure: true
      },
      redirectSignIn: endpoint.href,
      redirectSignOut: endpoint.href
    },
    API: {
      endpoints: [
        {
          name: "backend",
          endpoint: `https://api.${endpoint.hostname}`,
          custom_header: async () => { 
            // Alternatively, with Cognito User Pools use this:
            // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
          }
        }
      ]
    }
  }  
}

export default getAwsConfig()